@font-face {
  font-family: 'Prompt';
  font-weight: 400;
  src: url('./asset/fonts/Prompt-Regular.ttf'), local('Prompt');
}

@font-face {
  font-family: 'Prompt-Bold';
  font-weight: 700;
  src: url('./asset/fonts/Prompt-Bold.ttf'), local('Prompt-Bold');
}

@font-face {
  font-family: 'Prompt-SemiBold';
  font-weight: 600;
  src: url('./asset/fonts/Prompt-SemiBold.ttf');
}

@font-face {
  font-family: 'Prompt-Medium';
  font-weight: 500;
  src: url('./asset/fonts/Prompt-Medium.ttf');
}

@font-face {
  font-family: 'Prompt-Italic';
  src: url('./asset/fonts/Prompt-Italic.ttf');
}

@font-face {
  font-family: 'Prompt-Light';
  font-weight: 300;
  src: url('./asset/fonts/Prompt-Light.ttf');
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Prompt', sans-serif;
  position: relative;
  z-index: 0;
  min-height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  font-size: 14px;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}
.text-align-center {
  text-align: center;
}

.prevent-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-style {
  font-family: 'Prompt', sans-serif;
  font-stretch: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  font-style: normal;
}

.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #b5b5b5;
}

// React Context menu CSS
.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}
// END React Context Menu

// BEGIN MODAL CONTENT
.modal-content-container {
  width: 400px;
  border-radius: 10px;
  background: white;
  padding: 50px;
  z-index: 2;
  &.camera-detail {
    width: 610px;
    .modal-content-row-container {
      .label-material {
        font-family: 'Prompt', sans-serif;
        font-weight: bold;
        font-size: 14px;
      }
      .text-input-container input {
        font-family: 'Prompt', sans-serif;
        font-size: 24px !important;
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #979797;
          font-size: 24px !important;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          color: #979797;
          font-size: 24px !important;
        }
      }
      .MuiFormControlLabel-label {
        font-family: 'Prompt-Medium', sans-serif;
        font-size: 16px;
      }
    }
    #connect-camera-btn {
      font-family: 'Prompt-Medium', sans-serif;
      font-size: 14px;
    }
  }
  .signed-apikey-input {
    input {
      font-size: 20px !important;
    }
  }
  .modal-content-header-container {
    font-family: 'Prompt', sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f2d39;
    margin-bottom: 30px;
  }
  .modal-content-row-container {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 30px;
    }
    &.half {
      display: flex;
      > div {
        &:last-child {
          margin-left: 20px;
        }
      }
    }
    .modal-input-container {
      flex: 1;
      position: relative;
      input,
      textarea {
        font-family: 'Prompt', sans-serif;
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid #4a4a4a;
        padding: 0;
        font-size: 24px !important;
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          font-family: 'Prompt', sans-serif;
          color: #979797;
          font-size: 24px !important;
        }
        ::-moz-placeholder {
          /* Firefox 19+ */
          font-family: 'Prompt', sans-serif;
          color: #979797;
          font-size: 24px !important;
        }
      }
      .validate-error-message {
        position: absolute;
        height: 25px;
        bottom: -27px;
        font-size: 12px;
        font-weight: 300;
        font-style: italic;
        line-height: 1.43;
        letter-spacing: normal;
        color: #d0021b;
      }
    }
    .resolution-time-icon {
      width: 40px;
    }
  }
  #update-project-save-btn,
  #copy-apikey-btn,
  #create-site-save-btn {
    font-size: 16px;
  }
}
.modal-user-management-container {
  width: 610px;
  border-radius: 10px;
  background: white;
  z-index: 2;
  padding-top: 40px;
  padding-bottom: 50px;
  .modal-user-management-header-container {
    height: 55px;
    font-family: 'Prompt', sans-serif;
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f2d39;
    padding: 0 50px;
    margin-bottom: 30px;
  }
  .modal-user-management-row-container {
    padding: 0 50px;
    margin-bottom: 20px;
    .label-material {
      font-family: 'Prompt', sans-serif;
      font-size: 14px;
      font-weight: bold;
    }
    .text-input-container input {
      font-family: 'Prompt', sans-serif;
      font-size: 24px !important;
      &::-webkit-input-placeholder {
        font-family: 'Prompt', sans-serif;
        font-size: 24px !important;
      }
    }
    &.modal-user-management-project-header {
      color: #1f2d39;
      opacity: 0.3;
      font-family: 'Prompt-Bold', sans-serif;
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    &.modal-user-management-project-list-container {
      background: #f7f7f7;
      height: 130px;
      padding-top: 20px;
      padding-bottom: 20px;
      overflow-y: auto;
    }
    .role-dropdown,
    .project-dropdown {
      > .label-wrapper {
        font-family: 'Prompt', sans-serif;
        color: rgba(31, 45, 57, 0.3);
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .modal-user-management-btn-container {
    display: flex;
    justify-content: center;
    padding: 0 50px;
    margin-top: 50px;
    .modal-user-management-btn {
      width: 300px;
      font-size: 14px;
    }
  }
}

.modal-apikey-container {
  width: 610px;
  border-radius: 10px;
  background: white;
  z-index: 2;
  padding-top: 40px;
  padding-bottom: 50px;
  .modal-apikey-header-container {
    height: 55px;
    font-family: 'Prompt-SemiBold', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1f2d39;
    padding: 0 50px;
    margin-bottom: 30px;
  }
  .modal-apikey-row-container {
    padding: 0 50px;
    margin-bottom: 20px;
    .text-input-container {
      input {
        &::-webkit-input-placeholder {
          font-family: 'Prompt', sans-serif;
          font-size: 20px;
        }
      }
    }
    .project-dropdown {
      > .label-wrapper {
        font-family: 'Prompt-SemiBold', sans-serif;
        color: rgba(31, 45, 57, 0.3);
        font-size: 14px;
      }
    }
  }
  .modal-apikey-btn-container {
    display: flex;
    justify-content: center;
    padding: 0 50px;
    margin-top: 50px;
    .modal-apikey-btn {
      font-family: 'Prompt-Medium', sans-serif;
      width: 300px;
      font-size: 16px;
    }
  }
}

.speaker-modal-container {
  width: 610px;
  .modal-content-header-container {
    font-family: 'Prompt-SemiBold', sans-serif;
    font-size: 32px;
  }
  .modal-content-row-container {
    .label-material {
      font-family: 'Prompt-SemiBold', sans-serif;
      font-size: 14px;
    }
    input {
      &::-webkit-input-placeholder {
        font-family: 'Prompt', sans-serif;
        font-size: 20px;
      }
    }
  }
  .speaker-dropdown-container {
    .label-wrapper {
      font-family: 'Prompt-Bold', sans-serif;
      color: rgba(31, 45, 57, 0.3);
      font-size: 14px;
      margin: 0;
    }
  }
  .speaker-btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .speaker-modal-btn {
      width: 100%;
      margin: 0 20px;
      &#delete-speaker-btn {
        color: #dc3545;
        border: 1px solid #dc3545;
        &:disabled {
          color: white;
          border: 1px solid #d8d8d8;
        }
      }
    }
  }
}

.modal-delete-project-container {
  width: 400px;
  border-radius: 10px;
  background: white;
  z-index: 2;
  padding: 67px 50px 50px 50px;
  .title-container {
    font-family: 'Prompt', sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  .message-container {
    text-align: center;
    word-wrap: break-word;
    font-family: 'Prompt', sans-serif;
    font-size: 18px;
    line-height: 1.5;
    p {
      font-family: 'Prompt', sans-serif;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
  }
  .text-input-wrapper {
    margin-top: 35px;
    .label-wrapper {
      font-family: 'Prompt', sans-serif;
      font-size: 14px;
      font-weight: bold;
    }
    #delete-project-name-input {
      font-family: 'Prompt', sans-serif;
      font-size: 24px !important;
      &::-webkit-input-placeholder {
        font-size: 24px !important;
      }
    }
  }
  .btn-group-container {
    margin-top: 37px;
    display: flex;
    justify-content: space-between;
    #delete-project-cancel-btn {
      width: 140px;
    }
    #delete-project-delete-btn {
      width: 140px;
    }
  }
}

.modal-delete-color-container {
  width: 400px;
  border-radius: 10px;
  background: white;
  z-index: 2;
  padding: 67px 50px 50px 50px;
  .title-container {
    font-family: 'Prompt-Bold', sans-serif;
    font-size: 24px;
    text-align: center;
  }
  .message-container {
    text-align: center;
    word-wrap: break-word;
    font-family: 'Prompt', sans-serif;
    font-size: 17px;
    line-height: 1.17;
  }
  .btn-group-container {
    margin-top: 37px;
    display: flex;
    justify-content: space-between;
    #del-color-cancel-btn {
      width: 140px;
    }
    #del-color-delete-btn {
      width: 140px;
    }
  }
}
// END MODAL CONTENT

.confirm-modal {
  .confirm-message {
    margin-bottom: 20px;
  }
  .confirm-btn-wrapper {
    display: flex;
    .confirm-btn {
      width: 100%;
      margin-right: 10px;
      display: flex;
      justify-content: space-around;
      &:last-child {
        margin-right: 0;
      }
      button {
        height: 40px;
        width: 100px;
        padding: 0;
      }
    }
  }
}
